/**
 * Represents the structure of an API error response from the server
 */
type APIErrorResponse = {
  Code: string;
  Message: string;
  [key: string]: any; // For any additional fields in the error response
};

/**
 * Configuration options for creating an APIError
 */
type APIErrorConfig = {
  code: string;
  message: string;
  statusCode: number;
  path: string;
  details?: any;
};

type ReportStatus = {
  /** Whether an attempt was made to report this error */
  attempted: boolean;
  /** Timestamp of when the report attempt was made */
  timestamp?: number;
  /** Service that was used to report (e.g., 'sentry') */
  service?: string;
  /** Any additional metadata about the reporting attempt */
  meta?: Record<string, any>;
};

/**
 * Custom error class for handling API-specific errors
 * Includes Sentry integration support and detailed error context
 */
export class APIError extends Error {
  /** Unique error code from the API */
  readonly code: string;

  /** HTTP status code */
  readonly statusCode: number;

  /** API endpoint path where the error occurred */
  readonly path: string;

  /** Additional error details */
  readonly details?: any;

  /** Timestamp when the error was created */
  readonly timestamp: number;

  /** Structured context for error tracking */ // SENTRY
  readonly context: {
    tags: Record<string, string>;
    extra: Record<string, any>;
  };

  /** Tracks the status of error reporting attempts */
  private reportStatus: ReportStatus = {
    attempted: false,
  };

  constructor({ code, message, statusCode, path, details }: APIErrorConfig) {
    super(message);

    // Basic error properties
    this.name = "APIError";
    this.code = code;
    this.statusCode = statusCode;
    this.path = path;
    this.details = details;
    this.timestamp = Date.now();

    // Structure data for error tracking (Sentry)
    this.context = {
      tags: {
        errorType: "api_error",
        "romaSentry-errorCode": code,
        "romaSentry-api-path": path,
        "romaSentry-status-code": statusCode.toString(),
        "romaSentry-environment": import.meta.env.MODE,
        "romaSentry-web-user": details?.userId,
        "romaSentry-method": details?.method,
      },
      extra: {
        ...details,
        apiPath: path,
        timestamp: new Date(this.timestamp).toISOString(),
        "roma-request-body": details?.requestBody,
      },
    };

    // Maintains proper stack trace for where our error was thrown
    // Error.captureStackTrace(this, this.constructor);
    // Only call captureStackTrace if it exists (i.e., in Node.js)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, this.constructor);
    }

    // Ensures instanceof works correctly
    Object.setPrototypeOf(this, APIError.prototype);
  }

  /**
   * Creates an APIError instance from an API response
   */
  static fromResponse(
    data: APIErrorResponse,
    statusCode: number,
    path: string,
    details?: {
      method?: string;
      requestBody?: any;
      userId?: string;
    }
  ): APIError {
    return new APIError({
      code: data.Code || "UNKNOWN_ERROR",
      message: data.Message || "An unknown error occurred",
      statusCode,
      path,
      details: {
        ...data,
        method: details?.method,
        requestBody: details?.requestBody,
        userId: details?.userId,
      },
    });
  }

  /**
   * Creates an authentication error
   */
  static authenticationError(
    path: string,
    details?: {
      method?: string;
      requestBody?: any;
      userId?: string;
    }
  ): APIError {
    return new APIError({
      code: "AUTHENTICATION_ERROR",
      message: "Unable to get user session token",
      statusCode: 401,
      path,
      details: {
        timestamp: new Date().toISOString(),
        method: details?.method,
        requestBody: details?.requestBody,
        userId: details?.userId,
      },
    });
  }

  /**
   * Creates an invalid request body error
   */
  static invalidBodyError(
    body: any,
    details?: {
      method?: string;
      userId?: string;
    }
  ): APIError {
    return new APIError({
      code: "INVALID_BODY",
      message: `Invalid body type ${typeof body}`,
      statusCode: 400,
      path: "request-building",
      details: {
        providedBody: body,
        method: details?.method,
        userId: details?.userId,
      },
    });
  }

  /**
   * Creates a network error
   */
  static networkError(
    path: string,
    originalError: Error,
    details?: {
      method?: string;
      requestBody?: any;
      userId?: string;
    }
  ): APIError {
    return new APIError({
      code: "NETWORK_ERROR",
      message: "Failed to connect to the server",
      statusCode: 0,
      path,
      details: {
        originalError: {
          message: originalError.message,
          name: originalError.name,
          stack: originalError.stack,
        },
        method: details?.method,
        requestBody: details?.requestBody,
        userId: details?.userId,
      },
    });
  }

  /**
   * Creates a response parsing error
   */
  static parseError(
    path: string,
    contentType: string | null,
    error: Error,
    details?: {
      method?: string;
      requestBody?: any;
      userId?: string;
    }
  ): APIError {
    return new APIError({
      code: "RESPONSE_PARSING_ERROR",
      message: "Failed to parse response from server",
      statusCode: 0,
      path,
      details: {
        contentType,
        parseError: {
          message: error.message,
          name: error.name,
          stack: error.stack,
        },
        method: details?.method,
        requestBody: details?.requestBody,
        userId: details?.userId,
      },
    });
  }

  /**
   * Gets error severity based on status code
   */
  private getSeverityLevel(): "error" | "warning" | "info" {
    if (this.statusCode >= 500) return "error";
    if (this.statusCode >= 400) return "warning";
    return "info";
  }

  private sanitizePasswordInBody(data: any): any {
    if (!data) return data;

    if (typeof data === "object") {
      const sanitized = { ...data };

      for (const key in sanitized) {
        if (key.toLowerCase().includes("password")) {
          sanitized[key] = "[REDACTED]";
        } else if (typeof sanitized[key] === "object") {
          // Recursively sanitize nested objects
          sanitized[key] = this.sanitizePasswordInBody(sanitized[key]);
        }
      }

      return sanitized;
    }

    return data;
  }

  /**
   * Gets formatted context for Sentry
   */
  getSentryContext() {
    const sanitizedExtra = {
      ...this.context.extra,
      requestBody: this.context.extra?.requestBody
        ? this.sanitizePasswordInBody(this.context.extra.requestBody)
        : this.context.extra?.requestBody,
    };

    return {
      tags: this.context.tags,
      extra: sanitizedExtra,
      fingerprint: [
        "{{ default }}",
        this.code,
        this.path,
        this.statusCode.toString(),
      ],
      level: this.getSeverityLevel(),
    };
  }

  /**
   * Gets a user-friendly error message
   */
  getDisplayMessage(): string {
    switch (this.code) {
      case "AUTHENTICATION_ERROR":
        return "Please log in to continue";
      case "NETWORK_ERROR":
        return "Unable to connect to the server. Please check your internet connection";
      case "INVALID_BODY":
        return "Invalid request data";
      default:
        return this.message;
    }
  }

  /**
   * Marks this error as reported to an error tracking service
   */
  markAsReported(service: string, meta?: Record<string, any>) {
    this.reportStatus = {
      attempted: true,
      timestamp: Date.now(),
      service,
      meta,
    };
  }

  /**
   * Gets the current reporting status of this error
   */
  getReportStatus(): ReportStatus {
    return { ...this.reportStatus };
  }

  /**
   * Checks if an attempt was made to report this error
   */
  wasReportAttempted(): boolean {
    return this.reportStatus.attempted;
  }

  // END TESTING

  /**
   * Converts the error to a plain object for logging
   */
  toJSON() {
    return {
      name: this.name,
      code: this.code,
      message: this.message,
      statusCode: this.statusCode,
      path: this.path,
      timestamp: this.timestamp,
      details: this.details,
      reportStatus: this.reportStatus,
    };
  }

  getDebugInfo() {
    return {
      name: this.name,
      message: this.message,
      code: this.code,
      statusCode: this.statusCode,
      path: this.path,
      details: this.details,
      timestamp: this.timestamp,
      context: {
        tags: this.context.tags,
        extra: this.context.extra,
      },
      reportStatus: { ...this.reportStatus },
      stack: this.stack,
    };
  }
}

export const simulateApiError = (
  {
    path,
    code = "MALFORMED",
    message = "Some error occurred",
    statusCode = 400,
    method = "GET",
    requestBody,
    userId,
  }: {
    path: string;
    code?: string;
    message?: string;
    statusCode?: number;
    method?: string;
    requestBody?: any;
    userId?: string;
  },
  ms = 1500
) =>
  new Promise((_, reject) => {
    setTimeout(() => {
      reject(
        new APIError({
          path,
          code,
          message,
          statusCode,
          details: {
            method,
            requestBody,
            userId,
          },
        })
      );
    }, ms);
  });
